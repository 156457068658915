.container {
  padding: 0 2rem;
}

.main {
  min-height: 100vh;
  padding: 4rem 0;
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.title a {
  color: #f213a4;
  text-decoration: none;
}

.title a:hover,
.title a:focus,
.title a:active {
  text-decoration: underline;
}

.title {
  margin: 0;
  line-height: 1.15;
  font-size: 4rem;
}

.title,
.description {
  text-align: center;
}

.connect {
  margin-bottom: 2rem;
  width: 230px;
}

.description {
  margin-top: 3rem;
  margin-bottom: 2rem;
  line-height: 1.5;
  font-size: 1.5rem;
}

.code {
  background: #fafafa;
  border-radius: 5px;
  padding: 0.75rem;
  font-size: 1.1rem;
  font-family: Menlo, Monaco, Lucida Console, Liberation Mono, DejaVu Sans Mono,
    Bitstream Vera Sans Mono, Courier New, monospace;
}

.grid {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  max-width: 1200px;
}

.card {
  background-color: #1b2129;
  margin: 1rem;
  padding: 1.5rem;
  text-align: left;
  color: inherit;
  text-decoration: none;
  border: 1px solid #eaeaea;
  border-radius: 10px;
  transition: color 0.15s ease, border-color 0.15s ease;
  max-width: 350px;
}

.card:hover,
.card:focus,
.card:active {
  background-color: #272c34;
  color: #0ea5e9;
  border-color: #0ea5e9;
}

.card h2 {
  margin: 0 0 1rem 0;
  font-size: 1.5rem;
}

.card p {
  margin: 0;
  font-size: 1.25rem;
  line-height: 1.5;
}

.logo {
  height: 1em;
  margin-left: 0.5rem;
}

@media (max-width: 600px) {
  .grid {
    width: 100%;
    flex-direction: column;
  }
}

.card,
.footer {
  border-color: #222;
}
.code {
  background: #111;
}
.logo img {
  filter: invert(1);
}

.buttons-sections{
  display: flex;
  flex-direction: column;
}

.button-item {
  margin: 6px;
  /* width: 100%; */
  padding: 16px 16px;
  border-radius: 32px;
  font-size: 14px;
  background: #9656E4;
  font-weight: 500;
  border: none;
  cursor: pointer;
}

.button-item:hover {
  background: rgb(63, 62, 62);
}

.nftBoxGrid {
  width: 1200px;
  max-width: 95vw;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 1%;
  margin-top: 28px;
  flex-wrap: wrap;
}

.nftBox {
  border-radius: 16px;
  border: 1px solid #ccc;
  width: 19%;
  min-height: 200px;
}

.spacerBottom {
  margin-bottom: 16px;
}

.mainButton {
  cursor: pointer;
  display: inline-flex;
  appearance: none;
  align-items: center;
  -webkit-box-align: center;
  justify-content: center;
  white-space: nowrap;
  vertical-align: middle;
  outline: 2px solid transparent;
  outline-offset: 2px;
  line-height: 1.2;
  font-weight: 600;
  transition-property: background-color, border-color, color, fill, stroke,
    opacity, box-shadow, transform;
  transition-duration: 200ms;
  height: 3rem;
  min-width: 3rem;
  font-size: 1rem;
  background: #e5e5ea;
  background-image: linear-gradient(to left, #cc25b3 0%, #418dff 101.52%);
  color: #fff;
  width: 180px;
  text-align: center;
  border-radius: 9999px;
  border-style: none;
}

.mainButton:hover {
  opacity: 0.8;
}

/* Between 1200 and 800 */
@media only screen and (min-width: 800px) and (max-width: 1200px) {
  .nftBox {
    width: 49%;
    margin-top: 16px;
  }
}

/* beneath 800
*/
@media only screen and (max-width: 800px) {
  .nftBoxGrid {
    justify-content: center;
  }
  .nftBox {
    width: 90%;
    margin-top: 16px;
  }
}

/* Screen less than 630, then hide .center */
@media only screen and (max-width: 630px) {
  .center {
    display: none;
  }
}

.spacerTop {
  margin-top: 16px;
}

.bigSpacerTop {
  margin-top: 32px;
}

.spacerBottom {
  margin-bottom: 16px;
}

.detailPageContainer {
  max-width: 800px;
}

.detailPageHr {
  margin-top: 0px;
  margin-bottom: 32px;
}

.lightPurple {
  color: #e011a7;
}

.nftMedia {
  width: 100%;
  max-height: 300px;
  border-radius: 15px;
}

.noGapBottom {
  margin-bottom: 0px;
}

.centered {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.nftBoxGrid {
  width: 1200px;
  max-width: 95vw;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 1%;
  margin-top: 28px;
  flex-wrap: wrap;
}

.nftBox {
  border-radius: 16px;
  border: 1px solid #ccc;
  width: 19%;
  min-height: 200px;
}

/* Between 1200 and 800 */
@media only screen and (min-width: 800px) and (max-width: 1200px) {
  .nftBox {
    width: 49%;
    margin-top: 16px;
  }
}

/* beneath 800
*/
@media only screen and (max-width: 800px) {
  .nftBoxGrid {
    justify-content: center;
  }
  .nftBox {
    width: 90%;
    margin-top: 16px;
  }
}

.nftMedia {
  width: 100%;
  max-height: 300px;
  border-radius: 15px;
}